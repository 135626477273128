import React, { useState, useEffect } from "react";
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { checkPermission } from '../../utils_v2';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { getPermissionKey } from "..";

export const EngineLlpList = ({ item, editField, onFieldChange, sheetError, setSheetError, onResetError, params, onDelete, onEdit, thurstInfo, tabIndex, oldFlow }) => {
    const [showMore, setShowMore] = useState({});
    const StatusCode = item.part_numbers_dropdown?.filter((option => option.part_number === item?.part_number))[0]?.status;
    const modules= [{label:'Fan',value:1},{label:'HPC',value:2},{label:'HPT',value:4},{label:'LPT',value:5},{label:'Gearboxes',value:6},{label:'Combustion',value:3},{label:'IPC',value:8},{label:'IPT',value:9},{label:'Compressor',value:10},{label:'Cold Section',value:11},{label:'Hot Section',value:12},{label:'Power Turbine',value:13},{label:'Others',value:7}]
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const calculateWidth = (minWidth, maxWidth, content, minCount, maxCount) => {
        let width = 50;
        if (content?.length < minCount) {
            width = minWidth;
        } else if (content?.length > minCount) {
            width = maxWidth;
        }

        return `${width}px`;
    }
    return (
        <TableRow >
            <TableCell>
            { <div>{item?.module || "--"}</div>}
            </TableCell>
            <TableCell>
                { item?.description && item?.description?.length > 25 ? (
                        <div >
                            {showMore[item?.field] ? (
                                <span> {item?.description}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                                </span>
                            ) : (
                                <span> {item.description.substring(0, 20)}
                                    <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                                </span>
                            )}
                        </div>
                    ) : (
                        <div style={{minWidth:'160px', maxWidth:'200px'}}>{item.description}</div> || '--'
                    )}
            </TableCell>
            <TableCell>{
                <div>{item?.part_number || "--"}</div>}
            </TableCell>
            <TableCell>{
                <div style={{minWidth:'130px', maxWidth:'180px'}}>{item?.serial_number || '--'}</div>}
            </TableCell>
             <TableCell>
                 <div >{item?.tsn ? (item?.tsn).toFixed(2) : '--'}</div>
             </TableCell>
             <TableCell>
             <div >{item?.csn || '--'}</div>
             </TableCell>
            {tabIndex=='1' || oldFlow?
            <TableCell>
               <div > {item?.cycles_max_limit }</div>
            </TableCell>:
            thurstInfo?.map((thrust, index) => (
                <TableCell>
                    {item?.operated_cycles_thrusts[index]?.csn || '--'}
                </TableCell>
            ))}
            { tabIndex=='1' || oldFlow?
            <TableCell>
               <div > {item?.cycles_remaining }</div>
            </TableCell>: 
            thurstInfo?.map((thrust, index) => (
                <TableCell >
                    {item?.cycles_max_limit_thrusts[index]?.cycles_max_limit || '--'}
                </TableCell>
            ))}
            {tabIndex=='1' || oldFlow?null: 
            thurstInfo?.map((thrust, index) => (
                <TableCell >
                    {item?.cycles_remaining_thrusts[index]?.cycles_remaining || '--'}
                </TableCell>
            ))}
            <TableCell>{
                (item?.remarks && item?.remarks?.length > 25 ? (
                    <div style={{ width: calculateWidth('100', '142', item?.remarks, '25', '') }}>
                        {showMore[item?.field] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 20)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) : (
                    <span>{item.remarks || '--'}</span>
                ))}
            </TableCell>
            {!editField?
                <TableCell>
                    {checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                    <ul className="list-inline" style={{textAlign:'center'}}>
                        {tabIndex=='1' || oldFlow ? null:<li className="list-inline-item hover-inline " onClick={onEdit}> <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip></li>}
                        <li className="list-inline-item hover-inline " onClick={onDelete}> <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip></li>
                    </ul>
                    :null}
                </TableCell> 
                : null}
        </TableRow>
    )
}
