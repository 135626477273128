import React, { useState, useEffect } from 'react';
import { browserHistory, Link } from 'react-router';
import { UserAssets, UserOwners, UserProfile, UserRoles } from '../components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { SettingsNavWrapper } from '../../elements'
import { globalGetService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { jetStreamInstance } from '../../../shared/components';
import { trackActivity } from '../../../utils/mixpanel';
const UserDetail = ({params}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        getUserDetail();
        trackActivity('Page Visited', { page_title: 'User Detail', item_type: 'Page Visit', event_desc: 'User Detail Page Visited' })
    },[]);
    const getUserDetail = () => {
        globalGetService(`console/get-user-details/${params.slug}/`,{lite:true})
        .then(response => {
            if(checkApiStatus(response)){
                setUserInfo(response.data.data)
            }else{
                browserHistory.push('/users/manage')
            }
        })
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 0 276px'}}>
            <SettingsNavWrapper />
            <div><Link style={{cursor:'pointer'}} className='flex-centered' to={'/users/manage'}> <ArrowBackIcon fontSize='small' color='primary'/> <span style={{color:'#3f51b5'}}>User(s)</span></Link></div>
            <Paper style={{marginBottom:'15px', padding:'10px'}}>
                <UserProfile 
                    params={params} 
                    profileType='userProfile'
                />
            </Paper>
            <Paper style={{marginBottom:'15px'}}>
                <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                    <Tab label="Role(s)"/>
                    <Tab label="Allowed Asset(s)"/>
                    <Tab label={`Allowed ${jetStreamInstance?"Owner/SPV":"Owner"}(s)`}/>
                </Tabs>
            </Paper>
            { tabIndex === 0 ?
                <div>
                    <UserRoles 
                        params={params} 
                        userInfo={userInfo}
                    />
                </div>:null
            }
            { tabIndex === 1 ?
                <div>
                    <UserAssets 
                        userInfo={userInfo}
                    />
                </div>:null
            }    
            { tabIndex === 2 ?
                <div>
                    <UserOwners 
                        userInfo={userInfo}
                    />
                </div>:null
            }    
        </section>
    )
}
export default UserDetail;