import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Grid, TextField, Paper, Button, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { checkApiStatus, checkPermission, getLocalStorageInfo, replaceEmptyNumber, replaceEmptyNumberZero } from '../../utils_v2';
import { globalGetService, globalPutService } from '../../../globalServices';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { PageLoader, STableLoader } from '../../shared_elements';
import { Skeleton } from '@material-ui/lab';
import { trackActivity } from '../../../utils/mixpanel';
import { titanAviInstance } from '../../../shared/components';
const AircraftConfig = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [acquisitionDetails, setAcquisitionDetails] = useState({});
    const [error, setError] = useState({});
    useEffect(() => {
        getAcquisitionDetails('skeletonLoader');
    }, []);
    const getAcquisitionDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/other_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAcquisitionDetails(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const onFieldChange = (key, value) => {
        setAcquisitionDetails({ ...acquisitionDetails, [key]: value });
    }
    const onEditAcquisitionDetails = () => {
        let validationInputs = {
            contractual_end_date:getLocalStorageInfo()?.defaultLessor?.id === 213 && acquisitionDetails?.contractual_end_date ? moment(acquisitionDetails.contractual_end_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "Acquisition Date cannot be lesser than Date Of Manufacture" : '',
            availability_date: acquisitionDetails?.availability_date ? moment(acquisitionDetails.availability_date).isSameOrAfter(moment()) ? '' : "Availability Date cannot be lesser than present date" : '',
            first_flight_date: acquisitionDetails?.first_flight_date ? moment(acquisitionDetails.first_flight_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "First Flight Date  cannot be lesser than Date Of Manufacture" : '',
            first_delivery_date: acquisitionDetails?.first_delivery_date ? moment(acquisitionDetails.first_delivery_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "First Delivery Date  cannot be lesser than Date Of Manufacture" : '',
            coi_exp_date: acquisitionDetails?.coi_exp_date ? moment(acquisitionDetails.coi_exp_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "C of I Exp Date  cannot be lesser than Date Of Manufacture" : '',
            coa_exp_date: acquisitionDetails?.coa_exp_date ? moment(acquisitionDetails.coa_exp_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "C of A Exp Date  cannot be lesser than Date Of Manufacture" : '',
            cor_exp_date: acquisitionDetails?.cor_exp_date ? moment(acquisitionDetails.cor_exp_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "C of R Exp Date  cannot be lesser than Date Of Manufacture" : '',
            acquisition_date: acquisitionDetails?.acquisition_date ? moment(acquisitionDetails.acquisition_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "Acquisition Date cannot be lesser than Date Of Manufacture" : '',
            cta_exp_date: acquisitionDetails?.cta_exp_date ? moment(acquisitionDetails?.cta_exp_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "CTA Date cannot be lesser than Date Of Manufacture" : '',
            delivery_date_to_current_operator: acquisitionDetails?.delivery_date_to_current_operator ? moment(acquisitionDetails?.delivery_date_to_current_operator).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "Delivery Date to Current Operator cannot be lesser than Date Of Manufacture" : '',
            next_delivery_date: acquisitionDetails?.next_delivery_date ? moment(acquisitionDetails?.next_delivery_date).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "Next Delivery Date cannot be lesser than Date Of Manufacture" : '',
        }
        if (acquisitionDetails?.acquisition_date && !moment(acquisitionDetails?.acquisition_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                acquisition_date: 'Acquisition date cannot be future date'
            }
        }
        if (acquisitionDetails?.first_delivery_date && !moment(acquisitionDetails?.first_delivery_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                first_delivery_date: 'First Delivery date cannot be future date'
            }
        }
        if (acquisitionDetails?.first_flight_date && !moment(acquisitionDetails?.first_flight_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                first_flight_date: 'First Delivery date cannot be future date'
            }
        }
        if (acquisitionDetails?.cta_exp_date && !moment(acquisitionDetails?.cta_exp_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                cta_exp_date: "CTA Date cannnot be future date"
            }
        }
        if (acquisitionDetails?.delivery_date_to_current_operator && !moment(acquisitionDetails?.delivery_date_to_current_operator).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                delivery_date_to_current_operator: "Delivery Date to Current Operator cannot be future date"
            }
        }
        if (acquisitionDetails?.next_delivery_date && !moment(acquisitionDetails?.next_delivery_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                next_delivery_date: "Enter Valid Next Delivery Date"
            }
        }
        if (acquisitionDetails?.delivery_date_to_current_operator && !moment(acquisitionDetails?.delivery_date_to_current_operator).isValid()) {
            validationInputs = {
                ...validationInputs,
                delivery_date_to_current_operator: "Enter Valid Delivery Date to Current Operator"
            }
        }
        if (acquisitionDetails?.cta_exp_date && !moment(acquisitionDetails?.cta_exp_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                cta_exp_date: "Enter Valid CTA Date"
            }
        }
        if (acquisitionDetails?.first_flight_date && !moment(acquisitionDetails?.first_flight_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                first_flight_date: "Enter Valid First Delivery date"
            }
        }
        if (acquisitionDetails?.first_delivery_date && !moment(acquisitionDetails?.first_delivery_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                first_delivery_date: "Enter Valid First Delivery Date"
            }
        }
        if (acquisitionDetails?.acquisition_date && !moment(acquisitionDetails?.acquisition_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                acquisition_date: "Enter Valid Acquisition Date"
            }
        }
        if (acquisitionDetails?.cor_exp_date && !moment(acquisitionDetails?.cor_exp_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                cor_exp_date: "Enter Valid C of R Exp Date"
            }
        }
        if (acquisitionDetails?.coa_exp_date && !moment(acquisitionDetails?.coa_exp_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                coa_exp_date: "Enter Valid C of A Exp Date"
            }
        }
        if (acquisitionDetails?.coi_exp_date && !moment(acquisitionDetails?.coi_exp_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                coi_exp_date: "Enter Valid C of I Exp Date"
            }
        }
        if (acquisitionDetails?.availability_date && !moment(acquisitionDetails?.availability_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                availability_date: "Enter Valid Availability Date"
            }
        }
        if (acquisitionDetails?.contractual_end_date && !moment(acquisitionDetails?.contractual_end_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                contractual_end_date: "Enter Valid Lease End Date"
            }
        }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            let payload = Object.assign({}, acquisitionDetails)
            payload = {
                ...payload,
                acquisition_date: acquisitionDetails?.acquisition_date ? acquisitionDetails.acquisition_date : null,
                cta_exp_date: acquisitionDetails?.cta_exp_date ? acquisitionDetails.cta_exp_date : null,
                delivery_date_to_current_operator: acquisitionDetails?.delivery_date_to_current_operator ? acquisitionDetails.delivery_date_to_current_operator : null,
                next_delivery_date: acquisitionDetails?.next_delivery_date ? acquisitionDetails.next_delivery_date : null,
                acquisition_price:acquisitionDetails?.acquisition_price?acquisitionDetails?.acquisition_price:null,
                contractual_end_date: acquisitionDetails?.contractual_end_date ? acquisitionDetails.contractual_end_date : null,
                availability_date: acquisitionDetails?.availability_date ? acquisitionDetails.availability_date : null,
                coi_exp_date: acquisitionDetails?.coi_exp_date ? acquisitionDetails.coi_exp_date : null,
                coa_exp_date: acquisitionDetails?.coa_exp_date ? acquisitionDetails.coa_exp_date : null,
                cor_exp_date: acquisitionDetails?.cor_exp_date ? acquisitionDetails.cor_exp_date : null,
                first_delivery_date: acquisitionDetails?.first_delivery_date ? acquisitionDetails.first_delivery_date : null,
                first_flight_date: acquisitionDetails?.first_flight_date ? acquisitionDetails.first_flight_date : null,

            }
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/other_details/`, replaceEmptyNumberZero(payload, ['tsn_at_acquisition', 'csn_at_acquisition', 'tslsv_at_acquisition', 'cslsv_at_acquisition']))
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Aircraft Aquisition & Other Details', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Aircraft Aquisition & Other Details', event_desc: 'Edited Aircraft Aquisition & Other Details data from Edit Aircraft Aquisition & Other Details form' });
                        getAcquisitionDetails('skeletonLoader')
                        setEdit(false);
                    }
                    setLoading(false);
                })
        } else {
            setError(validationInputs)
        }
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="console-forms-fields" style={{ padding: '10px', height: `${window.innerHeight - 220}px`, overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '20px' }}>
                    {checkPermission('technical_specs', 'aircraft', 'U') && !isEdit ?
                        <span style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { setEdit(true); }} fontSize="small" color='primary' />
                        </span> : null
                    }
                </p>
                {skeletonLoader ? < STableLoader count={4} /> : <>

                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="acquisition_date"
                                    label="Acquisition Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    maxDate={moment()}
                                    value={acquisitionDetails?.acquisition_date || null}
                                    onChange={(data, value) => { onFieldChange('acquisition_date', data?.format(backendDateFormat) ); setError({ ...error, 'acquisition_date': '' }) }}
                                    error={error?.acquisition_date ? true : false}
                                    helperText={error?.acquisition_date || ''}
                                    onFocus={() => setError({ ...error, 'acquisition_date': '' })}
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='tsn_at_acquisition'
                                label='TSN at Acquisition'
                                value={acquisitionDetails?.tsn_at_acquisition === null ? '' : acquisitionDetails?.tsn_at_acquisition}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_acquisition', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='csn_at_acquisition'
                                label='CSN at Acquisition'
                                value={acquisitionDetails?.csn_at_acquisition === null ? '' : acquisitionDetails?.csn_at_acquisition}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn_at_acquisition', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='acquired_from'
                                label='Acquired From'
                                value={acquisitionDetails?.acquired_from || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('acquired_from', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 50 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='tslsv_at_acquisition'
                                label='TSLSV at Acquisition'
                                value={acquisitionDetails?.tslsv_at_acquisition === null ? '' : acquisitionDetails?.tslsv_at_acquisition}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tslsv_at_acquisition', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='cslsv_at_acquisition'
                                label='CSLSV at Acquisition'
                                value={acquisitionDetails?.cslsv_at_acquisition === null ? '' : acquisitionDetails?.cslsv_at_acquisition}
                                fullWidth
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('cslsv_at_acquisition', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="cor_exp_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            C of R Exp Date
                                            <Tooltip title="Certificate of Registration" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    fullWidth

                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    value={acquisitionDetails?.cor_exp_date || null}
                                    onChange={(data, value) => { onFieldChange('cor_exp_date',data?.format(backendDateFormat)); setError({ ...error, 'cor_exp_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.cor_exp_date ? true : false}
                                    helperText={error?.cor_exp_date || ''}
                                    onFocus={() => setError({ ...error, 'cor_exp_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="coa_exp_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            C of A Exp Date
                                            <Tooltip title="Certificate of Airworthiness" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.coa_exp_date || null}
                                    onChange={(data, value) => { onFieldChange('coa_exp_date', data?.format(backendDateFormat)); setError({ ...error, 'coa_exp_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.coa_exp_date ? true : false}
                                    helperText={error?.coa_exp_date || ''}
                                    onFocus={() => setError({ ...error, 'coa_exp_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="cta_exp_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            CTA  Date
                                            <Tooltip title="Certificate of Technical Acceptance" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.cta_exp_date || null}
                                    onChange={(data, value) => { onFieldChange('cta_exp_date', data?.format(backendDateFormat)); setError({ ...error, 'cta_exp_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.cta_exp_date ? true : false}
                                    helperText={error?.cta_exp_date || ''}
                                    onFocus={() => setError({ ...error, 'cta_exp_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="coi_exp_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            C of I Exp Date
                                            <Tooltip title="Certificate of Insurance" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.coi_exp_date || null}
                                    onChange={(data, value) => { onFieldChange('coi_exp_date', data?.format(backendDateFormat)); setError({ ...error, 'coi_exp_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.coi_exp_date ? true : false}
                                    helperText={error?.coi_exp_date || ''}
                                    onFocus={() => setError({ ...error, 'coi_exp_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="availability_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            Availability Date
                                            <Tooltip title="The date when the aircraft will be available" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    minDate={moment()}
                                    value={acquisitionDetails?.availability_date || null}
                                    onChange={(data, value) => { onFieldChange('availability_date', data?.format(backendDateFormat)); setError({ ...error, 'availability_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.availability_date ? true : false}
                                    helperText={error?.availability_date || ''}
                                    onFocus={() => setError({ ...error, 'availability_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='next_use'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        Next use
                                        <Tooltip title="The date when the aircraft will be available for next use." placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.next_use || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('next_use', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='line_number'
                                label='Line Number'
                                value={acquisitionDetails?.line_number || ''}
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                margin="normal"
                                onChange={(e) => onFieldChange('line_number', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='variable_number'
                                label='Variable Number'
                                value={acquisitionDetails?.variable_number || ''}
                                inputProps={{ maxLength: 25 }}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('variable_number', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="first_delivery_date"
                                    label="First Delivery Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.first_delivery_date || null}
                                    onChange={(data, value) => { onFieldChange('first_delivery_date', data?.format(backendDateFormat)); setError({ ...error, 'first_delivery_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.first_delivery_date ? true : false}
                                    helperText={error?.first_delivery_date || ''}
                                    onFocus={() => setError({ ...error, 'first_delivery_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="first_flight_date"
                                    label="First Flight Date"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.first_flight_date || null}
                                    onChange={(data, value) => { onFieldChange('first_flight_date', data?.format(backendDateFormat)); setError({ ...error, 'first_flight_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.first_flight_date ? true : false}
                                    helperText={error?.first_flight_date || ''}
                                    onFocus={() => setError({ ...error, 'first_flight_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="delivery_date_to_current_operator"
                                    label="Delivery Date to Current Operator"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(assetInfoData?.date_of_manufacture)}
                                    value={acquisitionDetails?.delivery_date_to_current_operator || null}
                                    onChange={(data, value) => { onFieldChange('delivery_date_to_current_operator', (data)?.format(backendDateFormat)); setError({ ...error, 'delivery_date_to_current_operator': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.delivery_date_to_current_operator ? true : false}
                                    helperText={error?.delivery_date_to_current_operator || ''}
                                    onFocus={() => setError({ ...error, 'delivery_date_to_current_operator': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="next_delivery_date"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            Next Delivery Date
                                            <Tooltip title="The date when the aircraft will be delivered next." placement='right'  ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    format={fieldDateFormat}
                                    fullWidth
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    minDate={acquisitionDetails?.delivery_date_to_current_operator ? acquisitionDetails?.delivery_date_to_current_operator : moment()}
                                    value={acquisitionDetails?.next_delivery_date || null}
                                    onChange={(data, value) => { onFieldChange('next_delivery_date', data?.format(backendDateFormat)); setError({ ...error, 'next_delivery_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.next_delivery_date ? true : false}
                                    helperText={error?.next_delivery_date || ''}
                                    onFocus={() => setError({ ...error, 'next_delivery_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='etops_aircraft_model_certification'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        ETOPS Aircraft Model Certification
                                        <Tooltip title="Specifiy the Certification Standard.For Eg 60min or 120min or 180min." placement='right'  ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.etops_aircraft_model_certification || ''}
                                inputProps={{ maxLength: 25 }}
                                fullWidth
                                margin="normal"
                                onChange={(e) => { onFieldChange('etops_aircraft_model_certification', e.target.value); setError({ ...error, 'acquisition_date': '' }) }}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='etops_current_config_capacity_cmp_revision'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        ETOPS Current Config Capacity
                                        <Tooltip title="Specifiy the Current Operation Standard.For Eg 60min or 120min or 180min.Note:Certification & Current Operation can be different" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.etops_current_config_capacity_cmp_revision || ''}
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                margin="normal"
                                onChange={(e) => { onFieldChange('etops_current_config_capacity_cmp_revision', e.target.value); setError({ ...error, 'etops_current_config_capacity_cmp_revision': '' }) }}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='etops_operational_standard'
                                label='ETOPS Operational Standard'
                                value={acquisitionDetails?.etops_operational_standard || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) => onFieldChange('etops_operational_standard', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='act_provisions'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        ACT Provisions
                                        <Tooltip title="Is asset equipped with ACT Provision-Yes/No/NA" placement='right' style={{ maxWidth: 100 }} ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.act_provisions || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('act_provisions', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='rvsm'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        RVSM (Reduced Vertical Separation Minimum)
                                        <Tooltip title="Specifiy 'Complied' or 'Not Complied'" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.rvsm || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) => onFieldChange('rvsm', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='cdss'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        Cockpit Door Surveillance System
                                        <Tooltip title="Specifiy Yes/No/NA'" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.cdss || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('cdss', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                        {
                            getLocalStorageInfo()?.defaultLessor?.id === 213 ?
                                <Grid item md={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            disabled={!isEdit}
                                            margin="normal"
                                            name="contractual_end_date"
                                            label="Lease End Date"
                                            format={fieldDateFormat}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment(assetInfoData?.date_of_manufacture)}
                                            value={acquisitionDetails?.contractual_end_date || null}
                                            onChange={(data, value) => { onFieldChange('contractual_end_date', data?.format(backendDateFormat)); setError({ ...error, 'contractual_end_date': '' }) }}
                                            error={error?.contractual_end_date ? true : false}
                                            helperText={error?.contractual_end_date || ''}
                                            onFocus={() => setError({ ...error, 'contractual_end_date': '' })}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid> : null
                        }
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEdit}
                                name='etops'
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        ETOPS (Extended Range Twin Engine Operations)
                                        <Tooltip title="Specifiy 'YES' or 'NO'" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={acquisitionDetails?.etops || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('etops', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>                     
                        {/* <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='acquisition_price'
                                label="Acquisition Price"
                                value={acquisitionDetails?.acquisition_price || null}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('acquisition_price', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid> */}
                    </Grid></>           
                    }
                {isEdit ? <div className="divider" style={{ height: '51px' }}></div> : null}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button onClick={() => { setError(''); setEdit(false); getAcquisitionDetails() }} color='primary' variant='outlined' size='small'>Cancel</Button>
                        </li>
                        <li className='list-inline-item'>
                            <Button onClick={onEditAcquisitionDetails} color='primary' variant='contained' size='small'>Save</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(AircraftConfig);