import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection } from '../../shared_elements';
import { SettingsNavWrapper } from '../../elements'
import { ActiveList, ActiveUserHeader, AddUser } from '../components'
import { Table, TableBody, Paper, Grid, Button, TextField } from '@material-ui/core';
import { globalGetService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import { userFilters } from '../'
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
import { trackActivity } from '../../../utils/mixpanel';
const ActiveUsers = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sortInfo,setSortInfo] = useState({sort:'', sort_by:''});
    const [filter, setFilter] = useState({});
    const [addUser, setAddUser] = useState({modal:false})
    const [userInfo, setUserInfo] = useState({list:[], pagination:{}})
    useEffect(() => {
        getActiveUsers({page:1, per_page:50},'skeletonLoader');
        trackActivity('Page Visited', { page_title: 'Active Users', item_type: 'Page Visit', event_desc: 'Active Users Page Visited' })
    },[]);
    const getActiveUsers = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`v2/get-user-list/?exclude_fields=group_profile_order,profile_pic&exclude_count=1`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setUserInfo(response.data.data)
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query)
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getActiveUsers({...filter, ...sortQuery, page:1, per_page:userInfo.pagination.per_page},'pageLoader');
    }
    const onExportUsers = () => {
        globalExportService(`v2/get-user-list/`, {...filter, download:'xls'})
        .then(response => {
            downloadFileType(response, 'Users List.', 'xls')
            trackActivity('Active Users Exported', { page_title: 'Active Users', item_type: 'Export', event_desc: 'Exported Active Users Successfully' })
        });
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 0 276px', paddingBottom:'40px'}}>
             <DeploymentMessage />
            <SettingsNavWrapper />
            <div>
                { skeletonLoader ? <STableLoader count={6} />:
                    <>
                        <Paper style={{padding:'0 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={9}>
                                    <FilterComponent 
                                        filter={filter} 
                                        filterMenu={userFilters}
                                        getResponseBack={(applyFilter) => getActiveUsers({...applyFilter, page:1, per_page:userInfo.pagination.per_page}, 'pageLoader')}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        {checkPermission('settings', 'manage_user','C') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={() => setAddUser({modal:true})} color='primary' variant='contained' size='small'>Add User</Button>
                                            </li>:null
                                        }
                                        { checkPermission('settings', 'manage_user','EXP') ?
                                            <li className='list-inline-item'>
                                                <Button onClick={onExportUsers} color='primary' variant='outlined' size='small'>Export</Button>
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper square style={{padding:'5px 10px'}}>
                            <Table className='mui-table-format'>
                                <ActiveUserHeader 
                                    createSortHandler={createSortHandler}
                                    sortInfo={sortInfo}
                                />
                                <TableBody>
                                    {userInfo.list.map((item,index) =>
                                        <ActiveList 
                                            item={item}
                                            key={index}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                            <Pagination 
                                pagination={userInfo.pagination}
                                onChangePage={(event, newPage) => getActiveUsers({...filter, sort:sortInfo.sort, sort_by: sortInfo.sort_by, page:newPage+1, per_page: userInfo.pagination.per_page}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getActiveUsers({...filter, sort:sortInfo.sort, sort_by: sortInfo.sort_by, page:1, per_page: event.target.value}, 'pageLoader')}
                            />
                            {!userInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                        </Paper>
                    </>
                    
                }
            </div>
            {addUser.modal?
                <AddUser 
                    getResponseBack={()=>getActiveUsers({page:1, per_page:50})}
                    addUser={addUser}
                    toggleModalFn={()=> setAddUser({modal:false})}
                />:null
            }
            {isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default ActiveUsers;