import { Bankings, DistributionEmails, FavLessees, Introduction, OwnerLessor } from './components'
import ThirdPartyIntegration from './components/ThirdPartyIntegration'
import requireAuth from '../../utils/requireAuth'
import LesseeConfig from './components/LesseeConfig'
import ContactList from './components/ContactList'
import RoleBasedAccess from '../../utils/RoleBasedAccess'

export const configRoutes = [
    {
        path:'configure/introduction',
        component:requireAuth(RoleBasedAccess(Introduction,['console','introduction','R'])),
        key:'Introduction'
    },
    {
        path:'configure/distribution',
        component:requireAuth(RoleBasedAccess(DistributionEmails,['console','distribution_emails','R'])),
        key:'DistributionEmails'
    },
    {
        path:'configure/owner-lessor',
        component:requireAuth(RoleBasedAccess(OwnerLessor,['console','owner_details','R'])),
        key:'OwnerLessor'
    },
    {
        path:'configure/banking-details',
        component:requireAuth(RoleBasedAccess(Bankings,['console','banking_details','R'])),
        key:'Bankings'
    },
    {
        path:'configure/lessee',
        component:requireAuth(RoleBasedAccess(FavLessees,['console','add_lessee','R'])),
        key:'FavLessees'
    },
    {
        path:'configure/lessee/:slug',
        component:requireAuth(LesseeConfig),
        key:'FavLessees'
    },
    {
        path:'configure/third-party-integration',
        component:requireAuth(RoleBasedAccess(ThirdPartyIntegration,['console','3rd_party_integration','R'])),
        key:'ThirdPartyIntegration'
    },
    {
        path:'configure/contact-list',
        component:requireAuth(RoleBasedAccess(ContactList,['console','contact','R'])),
        key:'ThirdPartyIntegration'
    },
]